import React from 'react';
import {NotFoundSection,NotFoundSectionInner,NotFoundImg,NotFoundHeading,NotFoundPara,
NotFoundBtnBg} from './404.style';
import {CommonBtn} from './common.style'

const NotFound = () => (
    <NotFoundSection>
        <NotFoundSectionInner>
            <NotFoundImg src="../pagenotfound-images/not-found.jpg"/>
            <NotFoundHeading>
                Page not found!
            </NotFoundHeading>
            <NotFoundPara>
            Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.
            </NotFoundPara>
            <NotFoundBtnBg>
                <CommonBtn href="/">
                    Home
                </CommonBtn>
            </NotFoundBtnBg>
        </NotFoundSectionInner>
    </NotFoundSection>
  )
  
  export default NotFound;